<template>
  <div
    class="shadow bg-white q-py-md text-center text-subtitle1 rounded"
    @click="handleExpand"
  >
    <div
      v-if="isOpen"
      class="border-bottom q-pb-md q-mb-md"
    >
      <strong>{{ $t('Task') + ': ' + data.id }}</strong>

      <span> ({{ $moment(data.created.date).format(appOptions.formats.fullDate) }})</span>
    </div>

    <div>
      <div
        v-if="data.scannedCount > 0 && countDifference !== 0"
        class="row items-center justify-center text-subtitle1"
      >
        <div class="border border-gray rounded q-pa-xs">
          <strong>
            {{ originalTask.scannedCount || 0 }}
          </strong>
        </div>

        <div class="q-pa-xs">
          <span v-if="originalTask.scannedCount < data.scannedCount">
            +
          </span>

          <span v-else>
            -
          </span>
        </div>

        <div class="border border-warning rounded q-pa-xs">
          <strong>
            {{ countDifference }}
          </strong>
        </div>

        <div class="q-pa-xs">
          =
        </div>

        <div class="border border-success rounded q-pa-xs">
          <strong>
            {{ data.scannedCount || 0 }}
          </strong>
        </div>

        <div class="q-pa-xs">
          <strong>
            {{ '/' + data.count }}
          </strong>
        </div>
      </div>

      <div
        v-else
        class="row items-center"
      >
        <div class="col-4 text-caption">
          {{ $t('Amount') }}
        </div>

        <div class="col-8 text-subtitle1 q-mb-xs">
          <strong>
            {{ (data.scannedCount || 0) + '/' + data.count }}
          </strong>
        </div>
      </div>

      <div>
        <q-btn
          color="light-blue-9"
          size="sm"
          class="full-width"
          :label="barcodeTitle"
          :disable="barcodes.length <= 1"
          @click="handleBarcodes"
        />

        <q-slide-transition>
          <div
            v-if="isOpenBarcodes"
            class="q-py-xs text-caption text-center"
          >
            {{ barcodes.join(', ') }}
          </div>
        </q-slide-transition>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'PickingTask',
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      isOpen: true,
      isOpenBarcodes: false,
      appOptions: this.$appOptions,
      sub: null,
      countDifference: 0,
      originalTask: {
        scannedCount: 0
      }
    }
  },
  computed: {
    barcodes () {
      return this.getBarcodes(this.data)
    },
    barcodeTitle () {
      if (this.barcodes.length <= 0) {
        return this.$t('No barcodes')
      }

      return this.$t('Last barcode') + ': ' + this.barcodes[this.barcodes.length - 1]
    }
  },
  watch: {
    data: {
      handler (value) {
        if (value && value.id !== this.originalTask.id) {
          this.originalTask = { ...value }
          this.countDifference = 0
          return
        }

        this.countDifference = this.getCountDifference(this.data.scannedCount, this.originalTask.scannedCount)
      },
      deep: true
    }
  },
  mounted () {
    this.originalTask = { ...this.data }
    this.sub = this.$eventBus.subscribe('rules', ({ appOptions }) => {
      this.appOptions = appOptions
    })
  },
  unmounted () {
    this.sub.unsubscribe()
  },
  methods: {
    handleBarcodes () {
      this.isOpenBarcodes = !this.isOpenBarcodes
    },
    getBarcodeFromInstructions (product) {
      return ((product.instructions || []).find(({ type }) => type === 'barcode-scan') || { data: { barcode: [] } }).data.barcode
    },
    getBarcodes (product) {
      const barcodesFromInstructions = this.getBarcodeFromInstructions(product)

      if (barcodesFromInstructions.length <= 0 && product._embedded && product._embedded.productOffer) {
        return product._embedded.productOffer.barcodes || []
      }

      return barcodesFromInstructions
    },
    handleExpand () {
      this.isOpen = !this.isOpen
    },
    onImageLoadFailure (e) {
      e.target.src = this.fallbackImage
    },
    getCountDifference (originalCount = 0, newCount = 0) {
      if (isNaN(Number(originalCount))) {
        originalCount = 0
      }

      if (isNaN(Number(newCount))) {
        newCount = 0
      }

      return originalCount - newCount
    }
  }
}
</script>
